/* eslint-disable no-unused-vars */
import toast from 'react-hot-toast';

import {
  TRIP_REQUEST_SEND_COMMENT_REQUEST,
  TRIP_REQUEST_SEND_COMMENT_SUCCESS,
  TRIP_REQUEST_SEND_COMMENT_FAIL,
  TRIP_REQUEST_LIST_REQUEST,
  TRIP_REQUEST_LIST_REFRESH_REQUEST,
  TRIP_REQUEST_LIST_SUCCESS,
  TRIP_REQUEST_LIST_FAIL,
  TRIP_REQUEST_DETAILS_REQUEST,
  TRIP_REQUEST_DETAILS_SUCCESS,
  TRIP_REQUEST_DETAILS_FAIL,
  TRIP_REQUEST_MESSAGES_REQUEST,
  TRIP_REQUEST_MESSAGES_SUCCESS,
  TRIP_REQUEST_MESSAGES_FAIL,
  TRIP_REQUEST_SUPPLIER_MESSAGES_SUCCESS,
  TRIP_REQUEST_SUPPLIER_MESSAGES_FAIL,
  TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_REQUEST,
  TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_SUCCESS,
  TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_FAIL,
  TRIP_REQUEST_UPDATE_REQUEST,
  TRIP_REQUEST_UPDATE_SUCCESS,
  TRIP_REQUEST_UPDATE_FAIL,
  TRIP_REQUEST_APPROVE_REQUEST,
  TRIP_REQUEST_APPROVE_SUCCESS,
  TRIP_REQUEST_APPROVE_FAIL,
  TRIP_REQUEST_TRAFFIC_APPROVE_REQUEST,
  TRIP_REQUEST_TRAFFIC_APPROVE_SUCCESS,
  TRIP_REQUEST_TRAFFIC_APPROVE_FAIL,
  TRIP_REQUEST_ASSIGN_REQUEST,
  TRIP_REQUEST_ASSIGN_SUCCESS,
  TRIP_REQUEST_ASSIGN_FAIL,
  TRIP_REQUEST_COMPLETE_REQUEST,
  TRIP_REQUEST_COMPLETE_SUCCESS,
  TRIP_REQUEST_COMPLETE_FAIL,
  TRIP_REQUEST_DELETE_REQUEST,
  TRIP_REQUEST_DELETE_SUCCESS,
  TRIP_REQUEST_DELETE_FAIL,
  TRIP_REQUEST_RESPONSE_REQUEST,
  TRIP_REQUEST_RESPONSE_SUCCESS,
  TRIP_REQUEST_RESPONSE_FAIL,
  RESET_TRIP_REQUEST_RESPONSE,
  TRIP_REQUEST_SUPPLIER_MESSAGES_REQUEST,
  TRIP_REQUEST_MESSAGES_CLEAN,
  TRIP_REQUEST_SUPPLIER_MESSAGES_CLEAN,
} from '../constants/tripRequestConstants';
import httpClient from './utils/axiosInstance';

// This function, listTripRequests, is an asynchronous action creator used in Redux.
// It takes a single parameter, params, which contains any filters or options for the trip request list.
// The function returns another function that takes dispatch and getState as arguments, enabling it to dispatch actions and access the current state.

export const listTripRequests = (params) => async (dispatch, getState) => {
  try {
    //check if the refresh parameter is true, if so, dispatch the TRIP_REQUEST_LIST_REFRESH_REQUEST action it doesn't reload all component
    if (params.refresh) {
      dispatch({ type: TRIP_REQUEST_LIST_REFRESH_REQUEST });
    } else {
      dispatch({ type: TRIP_REQUEST_LIST_REQUEST });
    }

    const { data } = await httpClient.get(`/api/v1/trip-requests/`, {
      params: {
        ...params,
      },
    });

    try {
      //Upon successful response, it tries to construct a pagination object from the response data.
      var pagination = {
        totalItems: data.total_items,
        totalPages: data.total_pages,
        pageSize: data.page_size,
        currentPage: data.current,
        previousPageURL: data.previous,
        nextPageURL: data.next,
      };
    } catch (error) {
      console.error('Error processing pagination data:', error);

      // Provide default values or handle the absence of properties
      pagination = {
        totalItems: 0,
        totalPages: 0,
        pageSize: 0,
        currentPage: 0,
        previousPageURL: null,
        nextPageURL: null,
      };
    }
    //send the TRIP_REQUEST_LIST_SUCCESS action with the results, pagination, and filters as payload.
    dispatch({
      type: TRIP_REQUEST_LIST_SUCCESS,
      payload: {
        results: data.results,
        pagination: pagination,
        filters: data.filters,
      },
    });
  } catch (error) {
    console.error('error', error.response.data);
    dispatch({
      type: TRIP_REQUEST_LIST_FAIL,
      payload: error.response && error.response.data?.detail ? error.response.data?.detail : error.message,
    });
  }
};

export const listTripRequestsDownload = (params) => async (dispatch, getState) => {
  try {
    const response = await httpClient.get(`/api/v1/trip-requests/`, {
      params: {
        ...params,
        download: true,
      },
      responseType: 'blob',
    });

    // Create a Blob object from the response data - for cvs files
    const blob = new Blob([response.data], { type: 'text/csv' });

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'data.csv';

    // Append the link to the document and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Remove the link element from the document
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading CSV:', error);
    // Handle error as needed
  }
};
// is designed to fetch details for a specific trip request identified by its id.
//It's structured to work within a Redux framework, utilizing asynchronous actions to handle the data fetching process.
//This id is used to identify the specific trip request whose details are to be fetched. The function returns another function that takes
export const getTripRequestDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_DETAILS_REQUEST });
    const { data } = await httpClient.get(`/api/v1/trip-requests/${id}`);

    dispatch({
      type: TRIP_REQUEST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
//update the trip request identified by its id with the updatedData object.
export const updateTripRequest = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_UPDATE_REQUEST });

    const { data } = await httpClient.put(`/api/v1/trip-requests/${id}/`, updatedData);

    dispatch({
      type: TRIP_REQUEST_UPDATE_SUCCESS,
      payload: data,
    });
    toast.success('Viaje actualizado correctamente');
  } catch (error) {
    toast.error('Hubo un error actualizando el viaje 😢');
    dispatch({
      type: TRIP_REQUEST_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
// listTripRequestMessages is an asynchronous action creator that fetches messages for a specific trip request identified by its id.
export const listTripRequestMessages = (id) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_MESSAGES_REQUEST });

    const { data } = await httpClient.get(`/api/v1/trip-requests/${id}/messages`);

    dispatch({
      type: TRIP_REQUEST_MESSAGES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_MESSAGES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.response,
    });
  }
};
//action to clear the messages when messages is unmounted.
export const cleanlistTripRequestMessages = () => (dispatch) => {
  dispatch({ type: TRIP_REQUEST_MESSAGES_CLEAN });
};
// delete a specific message identified by its id.
export const deleteTripRequest = (id) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_DELETE_REQUEST });

    const { data } = await httpClient.delete(`/api/v1/trip-requests/${id}`);
    dispatch({
      type: TRIP_REQUEST_DELETE_SUCCESS,
    });
    toast.success('Viaje eliminado exitosamente');
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
// send a message in conversation in a message identified by its id.
export const respondToTripRequest = (id, messageData) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_RESPONSE_REQUEST });

    const { data } = await httpClient.post(`/api/v1/messaging/send-message`, messageData);

    dispatch({
      type: TRIP_REQUEST_RESPONSE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_RESPONSE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const resetTripRequestResponse = () => (dispatch) => {
  dispatch({ type: RESET_TRIP_REQUEST_RESPONSE });
};
// get the messages of the trip request identified  by id, this forward the messages to the supplier.
export const getTripRequestSupplierMessages = (id, number) => async (dispatch) => {
  try {
    dispatch({
      type: TRIP_REQUEST_SUPPLIER_MESSAGES_REQUEST,
    });
    const { data } = await httpClient.get(`/api/v1/messaging/trip-requests/${id}/suppliers/forward/${number}`);
    dispatch({
      type: TRIP_REQUEST_SUPPLIER_MESSAGES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_SUPPLIER_MESSAGES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

//forward bid request message to suppliers
export const forwardForwardBidRequestMessage = (messageData) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_REQUEST });

    await httpClient.post('/api/v1/trip-requests/forward/', messageData);

    dispatch({
      type: TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_SUCCESS,
    });
  } catch (error) {
    console.error('error', error.response);
    toast.error('Oh no! Algo salió mal😢');
    dispatch({
      type: TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
//action to clear the messages when messages is unmounted.
export const cleanlistTripRequestForwardMessages = () => (dispatch) => {
  dispatch({ type: TRIP_REQUEST_SUPPLIER_MESSAGES_CLEAN });
};
// Update a specific trip, aproving it mesa control. 
export const approveTripRequest = (id, isApproved) => async (dispatch, getState) => {
  try {
    dispatch({ type: TRIP_REQUEST_APPROVE_REQUEST });

    const approve = { is_approved: isApproved };
    const { data } = await httpClient.put(`/api/v1/trip-requests/${id}/approve/`, approve);
    dispatch({
      type: TRIP_REQUEST_APPROVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_APPROVE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
// Update a specific trip, aproving it traffic control.
export const approveTripRequestByTraffic = (id, isApproved) => async (dispatch, getState) => {
  try {
    dispatch({ type: TRIP_REQUEST_TRAFFIC_APPROVE_REQUEST });

    const approve = { is_approved_by_traffic: isApproved };
    const { data } = await httpClient.put(`/api/v1/trip-requests/${id}/approve/traffic/`, approve);
    dispatch({
      type: TRIP_REQUEST_TRAFFIC_APPROVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_TRAFFIC_APPROVE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
// assign a trip request to a specific supplier identified by its id.
export const assignTripRequest = (id, supplierId) => async (dispatch, getState) => {
  try {
    dispatch({ type: TRIP_REQUEST_ASSIGN_REQUEST });

    const assign = { assigned_transporter: supplierId };
    const { data } = await httpClient.put(`/api/v1/trip-requests/${id}/assign/`, assign);

    dispatch({
      type: TRIP_REQUEST_ASSIGN_SUCCESS,
      payload: data,
    });
    toast.success('Viaje Asignado 🚚');
  } catch (error) {
    toast.error('Oh no! Something went bad');
    dispatch({
      type: TRIP_REQUEST_ASSIGN_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const completeTripRequest = (id, isComplete) => async (dispatch, getState) => {
  try {
    dispatch({ type: TRIP_REQUEST_COMPLETE_REQUEST });

    const complete = { is_complete: isComplete };
    // const { data } = await axios.put(
    //   `/api/v1/trip-requests/${id}/complete/`,
    //   complete,
    //   config
    // );

    // Simulate a delay of 1.5 seconds
    await new Promise((resolve) => setTimeout(resolve, 1500));
    const data = { success: true };

    dispatch({
      type: TRIP_REQUEST_COMPLETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_COMPLETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
// send a comment in a trip request identified by its id.
export const sendCommentTripRequest = (id, messageData) => async (dispatch, getState) => {
  try {
    dispatch({ type: TRIP_REQUEST_SEND_COMMENT_REQUEST });

    const { data } = await httpClient.post(`/api/v1/trip-requests/${id}/comment/`, messageData);

    dispatch({
      type: TRIP_REQUEST_SEND_COMMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_SEND_COMMENT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
// mark a trip request as unread. for mark as read is in api of update a trip request.
export const unreadTripRequest = (id) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_DETAILS_REQUEST });

    const { data } = await httpClient.put(`/api/v1/trip-requests/${id}/unread/`);
    dispatch({
      type: TRIP_REQUEST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
// reject a trip with previously reasons
export const rejectTripRequest = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_DETAILS_REQUEST });

    const { data } = await httpClient.put(`/api/v1/trip-requests/${id}/reject`, {
      is_rejected: updatedData?.is_rejected,
      rejection_reason: updatedData?.rejection_reason,
    });
    dispatch({
      type: TRIP_REQUEST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
