// CustomSelect.js
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';
import './../App.css';

const InputDate = ({ onSelectChange, emptyText = 'Seleccionar', columnId, defaultValue }) => {
  const initialDate = typeof defaultValue === 'string' ? new Date(defaultValue) : defaultValue;
  const [selectedDate, setSelectedDate] = useState(initialDate || null);
  const [showCalendar, setShowCalendar] = useState(false);
  const handleDateChange = (date) => {
    setSelectedDate(date);
    onSelectChange(columnId, date);
    setShowCalendar(false);
  };

  const toggleCalendar = (e) => {
    e.preventDefault();
    setShowCalendar(!showCalendar);
  };

  const clearDate = (e) => {
    e.preventDefault();
    setSelectedDate(null);
    setShowCalendar(false);
    onSelectChange(columnId, null);
  };

  function renderSelectedText() {

    if (selectedDate instanceof Date && !isNaN(selectedDate.getTime())) {
      return selectedDate.toLocaleDateString();
    } else if (typeof selectedDate === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(selectedDate)) {
      const [year, month, day] = selectedDate.split('-');
      const date = new Date(year, month - 1, day);
      if (!isNaN(date.getTime())) {
        return date.toLocaleDateString();
      }
    }
    return 'Fecha';
  }

  return (
    <Box position={'relative'} display={'flex'} w="100%" h={38}>
      <button
        style={{
          border: '1px solid #A0AEC0',
          padding: 0,
          paddingLeft: '.6rem',
          paddingRight: '.7rem',
          borderRadius: '0.3rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          width: emptyText !== 'Seleccionar' && '100%',
          color:
            emptyText === 'Fecha descarga' || emptyText === 'Fecha disponible' || emptyText === 'Fecha'
              ? '#807F7F'
              : '#000',
        }}
        type="button"
        onClick={toggleCalendar}
      >
        <span>{renderSelectedText()}</span>

        {showCalendar ? (
          <ChevronUpIcon ml={1} onClick={toggleCalendar} />
        ) : (
          <ChevronDownIcon ml={1} onClick={toggleCalendar} />
        )}
      </button>
      {showCalendar && (
        <Box style={{ display: 'flex', position: 'absolute', top: 40, zIndex: 9999 }}>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            placeholderText="Seleccionar Fecha"
            inline
            name={columnId}
            className="date-picker"
            style={{ zIndex: 1000 }}
          />
        </Box>
      )}
      {selectedDate ? (
        <button onClick={clearDate} style={{ marginLeft: '0.5rem' }}>
          <CloseIcon fontSize="xs" fontWeight="bold" color="gray.500" />
        </button>
      ) : null}
    </Box>
  );
};

export default InputDate;
