import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Heading, Text, Button, Flex } from '@chakra-ui/react';

import AppLayout from '../components/utils/layouts/AppLayout';

const NotFoundPage = () => {
  //This Screen is used to display the not found page
  return (
    <AppLayout>
      <Flex justifyContent={'center'} width="100%" alignItems="center">
        <Box textAlign="center" my="20">
          <Heading mb="6" color="blue.500">
            404: Página no encontrada
          </Heading>
          <Text mb="6" fontSize={'md'} color="gray.900" fontWeight="bold">
            Lo sentimos, la página que estás buscando no existe.
          </Text>
          <Button
            colorScheme="blue"
            as={Link}
            to="/"
            _hover={{
              color: 'white',
              backgroundColor: 'blue.600',
            }}
          >
            Volver a la página de inicio
          </Button>
        </Box>
      </Flex>
    </AppLayout>
  );
};

export default NotFoundPage;
