/* eslint-disable no-unused-vars */
import axios from 'axios';

import { BASE_URL } from '../../appSettings';
// Create an axios instance with a base URL
const httpClient = axios.create({
  baseURL: BASE_URL,
});
// Add a request interceptor to the axios instance
httpClient.interceptors.request.use(
  async (config) => {
    // Retrieve user info from local storage
    const userInfoFromStorage = JSON.parse(localStorage.getItem('userInfo'));
    // If headers exist, set common headers
    if (config.headers) {
      config.headers['Content-Type'] = 'application/json'; // Set content type to JSON
      config.headers['Accept'] = 'application/json'; // Set accept type to JSON
      config.headers['Authorization'] = `Bearer ${userInfoFromStorage?.access}`; // Add authorization header with Bearer token
      return config; // Return modified config
    }
    return config; // Return original config if headers don't exist
  },
  (error) => {
    // Handle the error by rejecting the promise
    Promise.reject(error);
  }
);
// Function to decode JWT token
const decodeToken = () => {
  const userInfoFromStorage = JSON.parse(localStorage.getItem('userInfo'));

  if (!userInfoFromStorage) return;
  const tokenSections = userInfoFromStorage.access?.split('.'); // Split the token into its parts
  const encodedPayload = tokenSections[1]; // Get the payload part of the token
  const decodedPayload = atob(encodedPayload); // Decodifica en base64
  const decodedPayloadObj = JSON.parse(decodedPayload);
  return decodedPayloadObj; // Return the decoded payload object
};
const getUnixTimeStamp = (_) => Math.floor(new Date().getTime() / 1000);
const SECONDS = 4000;
// Add a response interceptor to the axios instance
// this is for refreshing the token
httpClient.interceptors.response.use(
  (response) => response, // Return the response if no error
  async (error) => {
    const userInfoFromStorage = JSON.parse(localStorage.getItem('userInfo'));
    const payload = decodeToken();
    if (error.response && error.response.status === 401) {
      const currentTimeStamp = getUnixTimeStamp();
      if (!userInfoFromStorage) return Promise.reject(error);
      if (currentTimeStamp > payload.exp - SECONDS) {
        try {
          const { data } = await axios.post(`${BASE_URL}/api/v1/token/refresh/`, {
            refresh: userInfoFromStorage?.refresh,
          });
          localStorage.setItem('userInfo', JSON.stringify(data));
          // Update the authorization header with the new token
          httpClient.defaults.headers.common['Authorization'] = `Bearer ${data.access}`;
          return httpClient.request(error.config);
        } catch (refreshError) {
          // Handle refresh token error
          console.error('Error al actualizar el token:', refreshError);
          localStorage.removeItem('userInfo');
          window.location.href = '/login'; // Redirig
        }
      } else {
        // Handle expired token case
        console.error('Token expired, please login again');
        localStorage.removeItem('userInfo');
        window.location.href = '/login';
      }
    }
    return Promise.reject(error); // Reject the promise with the error
  }
);

export default httpClient;
