/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Zoom from 'react-medium-image-zoom';
import { useDispatch, useSelector } from 'react-redux';

import { CloseIcon } from '@chakra-ui/icons';
import { Box, IconButton, Image, Text } from '@chakra-ui/react';

import { cleanlistTripRequestMessages, listTripRequestMessages } from '../../actions/tripRequestActions';
import ChatResponseBox from '../ChatResponseBox';
import FullChatComponent from '../FullChatComponent';
import 'react-medium-image-zoom/dist/styles.css';
import DetailSkeleton from '../utils/skeletons.jsx/DetailSkeleton';

const MessageContainer = ({ id, isOpen }) => {
  const dispatch = useDispatch();
  const messagesRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [onlyLoading, setOnlyLoading] = useState(true);
  const { loading: loadingMessageSent } = useSelector((state) => state.tripRequestResponse);
  const tripRequestMessages = useSelector((state) => state?.tripRequestMessages);

  const { loading, messages, priorMessages, laterMessages, chatName, isEndOfConversation, chatProviderId, error } =
    tripRequestMessages;

  useEffect(() => {
    if (!isOpen) return;
    setOnlyLoading(true);
    dispatch(listTripRequestMessages(id));
  }, [dispatch, isOpen, id, loadingMessageSent]);

  useEffect(() => {
    // Scroll to the "messages" section when the component mounts
    if (messages?.length === 0 || !onlyLoading || window.innerWidth < 990) return;
    messagesRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, onlyLoading, window.innerWidth]); // Empty dependency array to run this effect only once when the component mounts
  const TIMER_MS = 5000;

  useEffect(() => {
    if (!tripRequestMessages || !isOpen) return;
    const interval = setInterval(() => {
      setOnlyLoading(false);
      dispatch(listTripRequestMessages(id));
    }, TIMER_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [tripRequestMessages]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <Box
      height="570px"
      maxWidth={{ lg: '950px' }}
      width={'100%'}
      boxShadow={'md'}
      border="1px solid"
      borderColor={'gray.200'}
      borderRadius={'lg'}
      overflowY={'scroll'}
      sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
      px={4}
      py={4}
      gap={{ base: 4, md: 8, lg: 0 }}
    >
      {loading ? (
        <DetailSkeleton />
      ) : selectedImage ? (
        <Box>
          <Box display={'flex'} justifyContent={'flex-end'} mb={1}>
            <IconButton
              color="white"
              backgroundColor="red.500"
              fontWeight="bold"
              fontSize={16}
              onClick={() => setSelectedImage(null)}
              icon={<CloseIcon />}
            />
          </Box>
          <Zoom defaultZoomLevel={2}>
            <Image src={selectedImage} alt="Expanded Image" maxW="100%" maxH="100%" />
          </Zoom>
        </Box>
      ) : (
        <>
          <Text fontSize="xl" fontWeight="bold">
            {chatName}
          </Text>
          <Box p={4}>
            <FullChatComponent
              handleImageClick={handleImageClick}
              handleCloseModal={handleCloseModal}
              loading={loading}
              messages={messages}
              priorMessages={priorMessages}
              laterMessages={laterMessages}
              messagesRef={messagesRef}
              isEndOfConversation={isEndOfConversation}
            />
          </Box>
          <ChatResponseBox id={chatProviderId} />
        </>
      )}
    </Box>
  );
};

export default MessageContainer;
