// import toast from 'react-hot-toast';
import httpClient from './utils/axiosInstance';
import {
  BID_REQUEST_DETAIL_FAIL,
  BID_REQUEST_DETAIL_REQUEST,
  BID_REQUEST_DETAIL_SUCCESS,
  BID_REQUEST_FAIL_SET,
  BID_REQUEST_REQUEST_SET,
  BID_REQUEST_SUCCESS_SET,
  TRIP_REQUEST_LIST_REFRESH_REQUEST,
} from '../constants/bidRequestConstants';
// get all bid requests
export const getBidRequestsSet = () => async (dispatch) => {
  try {
    dispatch({
      type: BID_REQUEST_REQUEST_SET,
    });
    const response = await httpClient.get(`/api/v1/bid-sets/`);

    dispatch({
      type: BID_REQUEST_SUCCESS_SET,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: BID_REQUEST_FAIL_SET,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
// refresh bid requests
export const refreshBidRequestsSet = () => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_LIST_REFRESH_REQUEST });

    const response = await httpClient.get(`/api/v1/bid-sets/`);

    dispatch({
      type: BID_REQUEST_SUCCESS_SET,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: BID_REQUEST_FAIL_SET,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
// download bid set requests  as excel
export const bidSetpRequestsDownload = () => async () => {
  try {
    const response = await httpClient.get(`/api/v1/bid-requests/download/`, {
      responseType: 'blob',
    });

    // Create a Blob object from the response data
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'data.xlsx';

    // Append the link to the document and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Remove the link element from the document
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading bid set CSV:', error);
    // Handle error as needed
  }
};
// update a bid request item
export const updateItemResponseBidRequest = (id, params) => async (dispatch) => {
  try {
    dispatch({
      type: BID_REQUEST_DETAIL_REQUEST,
    });

    await httpClient.put(`/api/v1/bid-requests/${id}`, {
      ...params,
    });

    dispatch({
      type: BID_REQUEST_DETAIL_SUCCESS,
      // payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: BID_REQUEST_DETAIL_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
