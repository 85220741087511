import React, { useEffect, useState } from 'react';

import { Box, Text } from '@chakra-ui/react';

import TripRequestPaginator from '../components/TripRequestPaginator';
import BidGroupTokenTable from '../components/unprotected/bidGroupTokenTable';
import GridGroupTokenRequest from '../components/unprotected/GridGroupTokenRequest';

const BidRequestGroupTokenTemplate = ({
  transformedData = [],
  onChangeInputRate,
  rateValue,
  handleAcceptedBidRequest,
  isLoading,
  isAcceptedLoading,
  elementShow,
  setElementShow,
  fetchNextPage,
  fetchPreviousPage,
  pagination,
}) => {
  const [data, setData] = useState(transformedData || []);
  const [withMaxScreen, setWithMaxScreen] = useState(1375);
  const [readStatus, setReadStatus] = useState({});

  useEffect(() => {
    // Check if transformedData is not empty before setting it
    if (!transformedData?.length === 0) return;
    setData(transformedData);
  }, [transformedData]);

  if (data.length === 0)
    return (
      <Box
        pt={4}
        height={240}
        display="flex"
        justifyContent="center"
        alignItems="center"
        w="99.98%"
        position={'sticky'}
        bg="white"
        mt={20}
        style={{
          borderRight: '1px solid #e1e1e1', // Lighter border color
          borderBottom: '1px solid #e1e1e1', // Lighter border color
        }}
      >
        <Text fontWeight="normal">No hay solicitudes de viaje</Text>
      </Box>
    );
  return (
    <>
      {data?.map((item, index) => {
        return (
          <BidGroupTokenTable
            key={index}
            data={item?.bid_requests}
            setWithMaxScreen={setWithMaxScreen}
            setData={setData}
            withMaxScreen={withMaxScreen}
            elementShow={elementShow}
            readStatus={readStatus}
            setReadStatus={setReadStatus}
            setElementShow={setElementShow}
            onChangeInputRate={onChangeInputRate}
            rateValue={rateValue}
            handleAcceptedBidRequest={handleAcceptedBidRequest}
            isLoading={isLoading}
            isAcceptedLoading={isAcceptedLoading}
            showRate={item?.show_rate}
          />
        );
      })}
      {data?.map((item, index) => {
        return (
          <GridGroupTokenRequest
            data={item?.bid_requests}
            setWithMaxScreen={setWithMaxScreen}
            setData={setData}
            withMaxScreen={withMaxScreen}
            elementShow={elementShow}
            readStatus={readStatus}
            setReadStatus={setReadStatus}
            setElementShow={setElementShow}
            onChangeInputRate={onChangeInputRate}
            rateValue={rateValue}
            handleAcceptedBidRequest={handleAcceptedBidRequest}
            isLoading={isLoading}
            isAcceptedLoading={isAcceptedLoading}
            showRate={item?.show_rate}
            key={index}
          />
        );
      })}

      {data.length > 0 && (
        <TripRequestPaginator
          pagination={pagination}
          fetchNextPage={fetchNextPage}
          fetchPreviousPage={fetchPreviousPage}
          elementShow={elementShow}
          setElementShow={setElementShow}
          withMaxScreen={withMaxScreen}
        />
      )}
    </>
  );
};

export default BidRequestGroupTokenTemplate;
