import React from 'react'
import { Box } from '@chakra-ui/react'
import styled from "@emotion/styled";
import DataTable from './Table';
import JSON5 from 'json5';
import Chat from './Chat';
import MessageBubble from './MessageBubble';

const Container = styled(Box)`
  display:grid;
  grid-template-rows: auto  1fr;

`
const Content = styled(Box)`
  box-sizing: border-box;
  display:grid;
  gap: 1em;
  grid-template-columns: calc(50% - 1em) calc(50% - 1em);
  justify-content: space-between;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 700px;
  align-items: end;
  padding: 1em;
  position: relative;
`;

const Section = styled(Box)`
  position: sticky;
  top: 0;
  display:flex;
  height: 100%;
  flex-direction: column;
  gap: 1em;
  justify-content: end;
  
`

const PipelineEvent = ({ event }) => {
  const getPromptImages = (prompt) => {
    return prompt?.adjusted_image_urls
  }
  const getTables = (prompt) => {
    return prompt?.tables
  }

  const getResponse = (response) => {
    const cleaned = { ...response };
    delete cleaned.raw_prompt_input;
    delete cleaned.response_text;
    delete cleaned.token;
    delete cleaned.env;
    delete cleaned.response;
    delete cleaned.trips_founds;
    delete cleaned.trips_found;
    return cleaned
  };

  const getPromptInputData = (prompt) => {
    return prompt?.inputData
  }

  const getRawPromptInput = (response) => {
    return response?.raw_prompt_input
  }


  const getPromptData = (prompt) => {
    try {
      const promptData = JSON5.parse(prompt?.data)
      return promptData

    } catch (e) {
      // console.log('Error parsing prompt data:', e)
      const promptData = prompt?.data;
      return promptData

    }
  }

  const getOutputFormat = (prompt) => {
    return prompt?.output_headers ? sortKeysByValues(prompt?.output_headers) : null
  }

  const sortKeysByValues = (obj) => {
    // Convert the object into an array of [key, value] pairs
    const entries = Object.entries(obj);

    // Sort the entries based on the numerical value
    entries.sort((a, b) => a[1] - b[1]);

    // Extract and return the sorted keys
    return entries.map(entry => entry[0]);
  }


  const promptData = getPromptData(event?.prompt);
  const response = getResponse(event?.response);
  const adjustedImageUrls = getPromptImages(event?.prompt)?.flat()
  const tables = getTables(event?.prompt)?.flat()
  const rawPromptInput = getRawPromptInput(event?.response)
  const headers = getOutputFormat(event?.prompt)
  const lastRawPromptInput = rawPromptInput ? rawPromptInput[rawPromptInput.length - 1] : null
  const inputData = getPromptInputData(event?.prompt)
  const shouldFallBackToLastRawPromptInput = !promptData && !inputData && lastRawPromptInput && !adjustedImageUrls?.length

  console.log({ headers })
  console.log({ inputData })

  return (
    <Container>
      {rawPromptInput && <Chat messages={rawPromptInput} />}

      <Content>
        <Section style={{ alignSelf: "start" , justifyContent:"start" }}>

          {
            adjustedImageUrls?.length > 0 && adjustedImageUrls.map((url, index) => (
              <div style={{ posiotion: "sticky", alignSelf: "start", top: "0px", maxHeight: "100%" }} key={index}>
                <a href={url} target="_new" rel="noopener noreferrer">
                  <img src={url} alt={`prompt-${index}`} />
                </a>
              </div>
            ))
          }

          {promptData instanceof Object && <DataTable title={Object.keys(response).flat().join(" ")} data={[...Object.values(promptData)].flat()} />}
          {shouldFallBackToLastRawPromptInput && (<MessageBubble isLast type={Object.keys(lastRawPromptInput).join("")} content={Object.values(lastRawPromptInput).join("")} />)}
          {inputData && inputData.map((data, index) => {
            console.log({ data })
            return <DataTable key={index} title={`OCR table Recognition ${index + 1}`} data={data.flat()} />
          })}
        </Section>
        <Section>
          {tables?.length > 0 &&
            tables.map((table, index) => (
              <DataTable key={index} title={`OCR table Recognition ${index + 1}`} data={table} />
            ))
          }
          <DataTable headers={headers} title={Object.keys(response).flat().join(", ")} data={[...Object.values(response)].flat()} />

        </Section>
      </Content>
    </Container>

  )
}

export default PipelineEvent