/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getChatGroupVisualizer } from '../actions/chatVisualizerAction';
import AppLayout from '../components/utils/layouts/AppLayout';
import MessagesTemplates from '../templates/MessagesTemplates';
const MessagesScreen = () => {
  const dispatch = useDispatch();
  // Retrieve user details from the Redux store
  const { userDetails } = useSelector((state) => state.userDetails);
  // Retrieve chats and loading status from the Redux store
  const { chats, loading } = useSelector((state) => state.chatGroupVisualizer);
  // Extract company ID from user details
  const companyId = userDetails?.company?.id;

  useEffect(() => {
    // If userDetails is not available, do nothing
    if (!userDetails) return;
    // Dispatch action to get chat group visualizer data
    dispatch(getChatGroupVisualizer(companyId));
  }, [userDetails]);

  return (
    <AppLayout showFooter={false}>
      <MessagesTemplates chats={chats} loading={loading} />
    </AppLayout>
  );
};

export default MessagesScreen;
