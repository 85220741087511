/* eslint-disable no-unused-vars */
import axios from 'axios';

import { BASE_URL } from '../../appSettings';
// Create an axios instance with a base URL
const httpClientFormData = axios.create({
  baseURL: BASE_URL,
});
// Add a request interceptor to the axios instance
httpClientFormData.interceptors.request.use(
  async (config) => {
    // Retrieve user info from local storage
    const userInfoFromStorage = JSON.parse(localStorage.getItem('userInfo'));
    if (config.headers) {
      config.headers['Content-Type'] = 'multipart/form-data';
      config.headers['Accept'] = 'application/json';
      config.headers['Authorization'] = `Bearer ${userInfoFromStorage?.access}`;
      return config; // Return modified config
    }
    return config; // Return original config if headers don't exist
  },
  (error) => {
    // Handle the error by rejecting the promise
    Promise.reject(error);
  }
);
// Function to decode JWT token
const decodeToken = () => {
  const userInfoFromStorage = JSON.parse(localStorage.getItem('userInfo'));

  if (!userInfoFromStorage) return;
  const tokenSections = userInfoFromStorage.access?.split('.');
  const encodedPayload = tokenSections[1];
  const decodedPayload = atob(encodedPayload); // Decodifica en base64
  const decodedPayloadObj = JSON.parse(decodedPayload);
  return decodedPayloadObj;
};
// Function to get the current UNIX timestamp in seconds
const getUnixTimeStamp = (_) => Math.floor(new Date().getTime() / 1000);
const SECONDS = 4000;

httpClientFormData.interceptors.response.use(
  (response) => response,
  async (error) => {
    const userInfoFromStorage = JSON.parse(localStorage.getItem('userInfo'));
    const payload = decodeToken();
    if (error.response && error.response.status === 401) {
      const currentTimeStamp = getUnixTimeStamp();
      if (!userInfoFromStorage) return Promise.reject(error);
      if (currentTimeStamp > payload.exp - SECONDS) {
        try {
          const { data } = await axios.post(`${BASE_URL}/api/v1/token/refresh/`, {
            refresh: userInfoFromStorage?.refresh,
          });
          localStorage.setItem('userInfo', JSON.stringify(data));
          // Update the authorization header with the new token
          httpClientFormData.defaults.headers.common['Authorization'] = `Bearer ${data.access}`;
          return httpClientFormData.request(error.config);
        } catch (refreshError) {
          console.error('Error al actualizar el token:', refreshError);
          localStorage.removeItem('userInfo');
          window.location.href = '/login'; // Redirect to login page
        }
      } else {
        console.error('Token expired, please login again');
        localStorage.removeItem('userInfo');
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default httpClientFormData;
