import { CONVERSATIONS_FAIL, CONVERSATIONS_SUCCESS, CONVERSATIONS_REQUEST } from '../constants/conversationsConstants';
import httpClient from './utils/axiosInstance';
// get messages from each conversation id - carousel modal
export const getConversationsForwaders = (id) => async (dispatch) => {
  try {
    dispatch({ type: CONVERSATIONS_REQUEST });
    const { data } = await httpClient.get(`/api/v1/chats/${id}/messages`);
    dispatch({
      type: CONVERSATIONS_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: CONVERSATIONS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
