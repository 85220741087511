/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */
import { Text } from '@chakra-ui/react';

import RegularCell from '../RegularCell';
import ActionsAvailabilityButton from '../utils/buttons/ActionsAvailabilityButton';
// import AvailabilityStatusesTooltip from '../utils/tooltips/AvailabilityStatusesTooltip';
import MessageToolTip from '../utils/tooltips/MessageToolTip';

export const columns = (
  openDetailMessage,
  openSureModal,
  disabledButtons,
  handleReadStatusToggle,
  readStatus,
  openStatusesModal,
  is_staff
) =>
  [
    {
      accessorKey: 'offered_at',
      id: 'offered_at',
      header: 'FECHA DE OFERTA',
      isInteractive: false,
      size: 130,
      cell: RegularCell,
    },

    {
      accessorKey: 'unit_type',
      header: 'UNIDAD',
      isInteractive: false,
      size: 150,
      cell: RegularCell,
      enableColumnFilter: true,
      filterFn: 'includesString',
    },

    {
      accessorKey: 'origin',
      header: 'ORIGEN',
      isInteractive: false,
      size: 200,
      cell: RegularCell,
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      accessorKey: 'destinations',
      header: 'DESTINO',
      isInteractive: false,
      size: 200,
      cell: RegularCell,
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      accessorKey: 'start_date',
      header: 'FECHA DISPONIBLE',
      isInteractive: false,
      size: 170,
      cell: ({ getValue, row }) => {
        return (
          <>
            <RegularCell row={row} getValue={getValue} />
            <Text m={0} fontSize="12px" verticalAlign={'middle'} whiteSpace="nowrap" fontWeight={'normal'}>
              {row?.original.start_time?.split(':')?.slice(0, 2)?.join(':')}
            </Text>
          </>
        );
      },
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      accessorKey: 'carrier_name', // client_name_tag
      header: 'TRANSPORTISTA',
      isInteractive: false,
      size: 170,
      cell: RegularCell, // Render a regular text cell
      enableColumnFilter: true,
      filterFn: 'includesString',
    },

    {
      accessorKey: 'main_msg',
      header: 'MENSAJES',
      size: 210, // Set the desired size for the column
      isInteractive: false,
      isOpenMessage: true,
      cell: ({ row }) => <MessageToolTip row={row} openChatModal={() => {}} />,
    },

    {
      accessorKey: 'respond',
      header: null,
      size: 0, // Set the desired size for the column
      isInteractive: true,
      cell: ({ row }) => {
        return (
          <ActionsAvailabilityButton
            id={row?.original?.id}
            readStatus={readStatus}
            disabledButtons={disabledButtons}
            handleReadStatusToggle={handleReadStatusToggle}
            openSureModal={openSureModal}
            openDetailMessage={openDetailMessage}
            openStatusesModal={openStatusesModal}
          />
        );
      },
    },
  ].filter(Boolean);
