export const CHAT_VISUALIZER_GROUP_SUCCESS = 'CHAT_VISUALIZER_GROUP_SUCCESS';
export const CHAT_VISUALIZER_GROUP_LOADING = 'CHAT_VISUALIZER_GROUP_LOADING';
export const CHAT_VISUALIZER_GROUP_FAIL = 'CHAT_VISUALIZER_GROUP_FAIL';

export const CHAT_VISUALIZER_SUCCESS = 'CHAT_VISUALIZER_SUCCESS';
export const CHAT_VISUALIZER_LOADING = 'CHAT_VISUALIZER_LOADING';
export const CHAT_VISUALIZER_FAIL = 'CHAT_VISUALIZER_FAIL';

export const MESSAGE_DETAILS_SUCCESS = 'MESSAGE_DETAILS_SUCCESS';
export const CHAT_LOAD_MORE_MESSAGES = 'CHAT_LOAD_MORE_MESSAGES';
export const CHAT_VISUALIZER_LOADING_DETAILS = 'CHAT_VISUALIZER_LOADING_DETAILS';