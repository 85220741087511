/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SkeletonText,
  Box,
  Text,
} from '@chakra-ui/react';

import { getConversationsForwaders } from '../../actions/conversationActions';
import { getTripRequestDetails, resetTripRequestResponse } from '../../actions/tripRequestActions';
import Buttom from '../utils/buttons/Buttom';
import ConversationSkeleton from '../utils/skeletons.jsx/ConversationSkeleton';
import MyCarousel from './Carousel';
import ConversationDetail from './conversationDetail';
const customStyles = {
  backgroundColor: 'white',
  color: 'darkblue',
  display: 'flex',
  paddingBottom: 0,
  marginBottom: 0,
};

const CarouselModal = ({ isOpen, onClose, conversations, currentTripId, openForwardMessageModal }) => {
  const dispatch = useDispatch();
  const [conversationsData, setConversationsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentChat, setCurrentChat] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [onlyLoading, setOnlyLoading] = useState(true);
  const messagesRefs = conversationsData?.map(() => React.createRef());
  const { loading, success } = useSelector((state) => state.tripRequestResponse);

  const tripRequestDetails = useSelector((state) => state.tripRequestDetails);
  const { tripRequest, loading: isLoadingdetail } = tripRequestDetails;
  let conversationsTrips = tripRequest?.forwarded_supplier_chats || conversations;
  // Defines an asynchronous function to fetch conversation details for each trip.
  const getEachConversation = async () => {
    setIsLoading(true);
    // Maps over each conversation trip to dispatch an action that fetches conversation forwarders.
    // This creates an array of promises, one for each conversation.
    const promises = conversationsTrips.map((conversation) => {
      return dispatch(getConversationsForwaders(conversation));
    });
    // Waits for all promises to resolve and collects their results.
    // `Promise.all` ensures that all fetch operations complete before proceeding.
    const data = await Promise.all(promises);
    // Updates the state with the fetched conversation data.
    setConversationsData(data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (conversationsTrips.length === 0 || !isOpen) return;
    setOnlyLoading(true);
    getEachConversation();
  }, [conversationsTrips]);

  useEffect(() => {
    if (!success || !isOpen) return;
    getEachConversation();
    dispatch(resetTripRequestResponse());
  }, [loading, selectedChat]);

  const TIMER_MS = 60000;
  useEffect(() => {
    if (!conversationsData || !isOpen) return;
    const interval = setInterval(() => {
      setOnlyLoading(false);
      getEachConversation();
    }, TIMER_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [conversationsData]);

  const handleClose = () => {
    onClose();
    setConversationsData([]);
    setSelectedChat(null);
    setCurrentChat(null);
    conversationsTrips = null;
  };
  // This useEffect hook is used to automatically scroll to the last message in a conversation
  // under certain conditions. It operates based on the state of conversationsData, onlyLoading,
  // isLoadingdetail, and the window's innerWidth.
  useEffect(() => {
    const validateScroll = conversationsData && onlyLoading && !isLoadingdetail;
    const innerWidth = window.innerWidth;
    if (validateScroll && innerWidth >= 860) {
      const scrollTimeout = setTimeout(() => {
        conversationsData.forEach((conversation, index) => {
          const lastMessageRef = messagesRefs[index]?.current;
          if (lastMessageRef) {
            lastMessageRef.scrollIntoView({ behavior: 'instant' });
          }
        });
      }, 100);
      return () => clearTimeout(scrollTimeout);
    }

    if (validateScroll) {
      const element = messagesRefs[0]?.current;
      if (element) {
        element.scrollIntoView({ behavior: 'instant' });
      }
    }
  }, [conversationsData, onlyLoading, isLoadingdetail, window.innerWidth]);

  useEffect(() => {
    if (!isOpen) return;
    dispatch(getTripRequestDetails(currentTripId));
  }, [isOpen]);

  /**
   * This function, handleScrollToConversation, is designed to change the current chat and selected chat
   * based on the provided index and chatProviderId. After updating the chat context, it attempts to scroll
   * to the chat's corresponding message element in the UI.
   * @param {number} index
   * @param {number} chatProviderId
   */
  const handleScrollToConversation = (index, chatProviderId) => {
    setCurrentChat(chatProviderId);
    setSelectedChat(index);
    const element = messagesRefs[index]?.current;
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  const items = conversationsData?.map((conversation, index) => {
    const messages = conversation.context.later_messages;
    const lastMessages = messages[messages.length - 1]?.time;
    return (
      <button
        key={index}
        onClick={() => handleScrollToConversation(index, conversation.chat_provider_id)}
        style={{
          textAlign: 'left',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-center',
          alignItems: 'flex-center',
          height: '100%',
          marginTop: '10px',
          paddingTop: '10px',
          paddingBottom: 0,
          marginBottom: 0,
          paddingLeft: 0,
          width: '100%',
        }}
      >
        <Box
          color="gray.900"
          border="1px solid #ccc"
          borderBottom="0"
          w="95%"
          borderTopRadius="md"
          pl={4}
          pt={2}
          position={'sticky'}
          minHeight={'98px'}
        >
          <Text fontSize="lg" fontWeight="bold" textAlign="left" mb={0} cy="chat-name">
            {conversation?.chat_name}
          </Text>
          <Text fontSize="sm" textAlign="left" mt={0} color="gray.500">
            última respuesta: {lastMessages}
          </Text>
        </Box>
        <ConversationDetail
          key={index}
          chatName={conversation?.chat_name}
          messages={conversation?.messages}
          priorMessages={conversation?.context.prior_messages}
          laterMessages={messages}
          isEndOfConversation={conversation?.context?.is_latest_messages}
          loading={selectedChat === index && isLoading}
          chatProviderId={conversation?.chat_provider_id}
          isCurrentChat={currentChat === conversation?.chat_provider_id}
          laterMessagesRef={messagesRefs[index]}
        />
      </button>
    );
  });
  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="60%">
      <ModalOverlay />
      {isLoadingdetail || conversationsData?.length === 0 ? (
        <ModalContent style={customStyles} p={0} maxWidth={'1200px'}>
          <SkeletonText noOfLines={2} spacing="4" width={'20%'} pt={5} pl={4} />
          <ConversationSkeleton />
        </ModalContent>
      ) : (
        <ModalContent style={customStyles} p={0} maxWidth={'1200px'}>
          <ModalCloseButton />
          <ModalHeader mb={0} pb={0}>
            <Box m={0} p={0}>
              <Text fontSize="xl" fontWeight="bold" m={0} cy="client-name">
                {tripRequest?.channel?.client?.name}
              </Text>
            </Box>
            <Box display={{ base: 'block', md: 'flex' }} alignItems="center" w="100%" m={0} p={0}>
              <Text fontSize="sm" color="gray.900" m={0} mr={2} cy="route">
                Viaje: {tripRequest?.origin?.name || '-'} - {tripRequest?.destination?.name || '-'}
              </Text>
              <Buttom
                onClick={() => openForwardMessageModal(currentTripId, false)}
                text="Reenviar"
                color="blue.500"
                size="xs"
                variant="outline"
                mb={1}
                mt={1}
              />
            </Box>
          </ModalHeader>
          <ModalBody
            maxH={'100%'}
            overflowY="hidden"
            m={0}
            p={0}
            sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
            width={'100%'}
          >
            <MyCarousel items={items} />
          </ModalBody>
        </ModalContent>
      )}
    </Modal>
  );
};

export default CarouselModal;
