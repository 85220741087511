import React, { useState } from "react";
import { Box, Collapse, Button } from "@chakra-ui/react";
import styled from "@emotion/styled";

const Container = styled(Box)`
  width: 100%;
`;

const TabContainer = styled(Box)`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-bottom: 8px;
  overflow: hidden;
  width: 100%;
`;

const TabTitle = styled(Button)`
  width: 100%;
  background-color: var(--chakra-colors-blue-500);
  color: white;
  text-align: left;
  font-size: 16px;
  border: none;
  cursor: pointer;
  width: 100%;

  &:hover {
    transition: all 0.3s ease;
    background-color: var(--chakra-colors-blue-600);
  }
`;

const CollapsibleTab = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <TabContainer>
      <TabTitle onClick={() => setIsOpen(!isOpen)}>{title}</TabTitle>
      <Collapse in={isOpen} animateOpacity>
        <Box  bg="white">
          {children}
        </Box>
      </Collapse>
    </TabContainer>
  );
};

const VerticalCollapser = ({ tabs }) => {
  return (
    <Container>
      {
        tabs.map((tab, index) => (
          <CollapsibleTab key={index} title={tab.title}>
            {tab.content}
          </CollapsibleTab>
        ))
      }
    </Container>

  );
};

export default VerticalCollapser