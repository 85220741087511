/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { acceptedBidRequests, getBidRequests } from '../../../actions/bidRequestAction';
import AppLAyoutUnprotected from '../../../components/utils/layouts/AppLAyoutUnprotected';
import AppLoader from '../../../components/utils/skeletons.jsx/AppLoader';
import BidRequestGroupTokenTemplate from '../../../templates/BidRequestGroupToken';
const BidRequestGroup = () => {
  const [rateValue, setRateValue] = useState({});
  const [tablePage, setTablePage] = useState(1);
  const [elementShow, setElementShow] = useState(10);

  const dispatch = useDispatch();
  // Get the token parameter from the URL
  const { token } = useParams();
  const { loading, supplier: bidRequests } = useSelector((state) => state.bidRequest);
  const { loading: isAcceptedLoading } = useSelector((state) => state.bidRequestDetail);
  const onChangeInputRate = (id, e) => {
    setRateValue((prevValues) => ({
      ...prevValues,
      [id]: e.target.value,
    }));
  };
  // Handle acceptance of a trip request
  const handleAcceptedBidRequest = async (id, index) => {
    const params = {
      rate: rateValue[index],
      is_accepted: true,
    };
    try {
      dispatch(acceptedBidRequests(id, params));

      dispatch(getBidRequests(token, true));
    } catch (error) {
      toast.error('Error al aceptar la solicitud de viaje');
      console.error(error);
    }
  };
  //get bid requests when token is available - api need token to get bid requests
  useEffect(() => {
    if (!token) return;
    dispatch(getBidRequests(token));
  }, [token]);
// Refresh bid requests every 10 seconds if the bid request is not accepted
  useEffect(() => {
    if (isAcceptedLoading) return;
    const interval = setInterval(() => {
      dispatch(getBidRequests(token, true));
    }, 10000);

    return () => clearInterval(interval); // Clear interval on unmount
  }, [isAcceptedLoading]);

  const fetchNextPage = () => {
    // fetchTripRequests(sortConfig);
  };

  const fetchPreviousPage = () => {
    // fetchTripRequests(sortConfig);
  };

  const pagination = {
    currentPage: tablePage,
    nextPageURL: null,
    pageSize: elementShow,
    previousPageURL: null,
    totalItems: 9,
    totalPages: 1,
  };
  if (loading) return <AppLoader />;
  return (
    <AppLAyoutUnprotected>
      <BidRequestGroupTokenTemplate
        transformedData={bidRequests}
        onChangeInputRate={onChangeInputRate}
        rateValue={rateValue}
        handleAcceptedBidRequest={handleAcceptedBidRequest}
        isLoading={loading}
        isAcceptedLoading={isAcceptedLoading}
        elementShow={elementShow}
        setElementShow={setElementShow}
        tablePage={tablePage}
        setTablePage={setTablePage}
        fetchNextPage={fetchNextPage}
        fetchPreviousPage={fetchPreviousPage}
        pagination={pagination}
      />
    </AppLAyoutUnprotected>
  );
};

export default BidRequestGroup;
