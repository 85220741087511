import React, { useState } from 'react';
import {
  Box,
  Text,
  HStack,
  Avatar,
  Button,
  useClipboard,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

const messageStyles = {
  system: {
    bg: 'gray.100',
    textColor: 'gray.800',
    align: 'center',
  },
  human: {
    bg: 'blue.500',
    textColor: 'white',
    align: 'flex-end',
  },
  ai: {
    bg: 'blue.600',
    textColor: 'white',
    align: 'flex-start',
  },
  image_data: {
    bg: 'green.100',
    textColor: 'gray.800',
    align: 'center',
  },
};

const getFormattedContent = (content) => {
  try {
    const parsed = JSON.parse(content);
    const pretty = JSON.stringify(parsed, null, 2);
    const replaced = pretty.replaceAll('\\"', '"');
    return { raw: content, pretty: replaced };
  } catch (err) {
    const replaced = content.replaceAll('\\"', '"');
    return { raw: content, pretty: replaced };
  }
};

const MessageBubble = ({ type, content, isLast }) => {
  const styles = messageStyles[type] || messageStyles.system;
  const [showRaw, setShowRaw] = useState(false);

  const { raw, pretty } = getFormattedContent(content);
  const htmlRegex = /<html[\s\S]*<\/html>/i;
  const htmlMatch = raw.match(htmlRegex);

  const { onCopy } = useClipboard(raw);

  let displayedContent;

  if (htmlMatch && !showRaw) {
    const htmlBlock = htmlMatch[0];
    const startIndex = raw.indexOf(htmlBlock);
    const pre = raw.slice(0, startIndex);
    const post = raw.slice(startIndex + htmlBlock.length);

    // Display pre, html, and post
    displayedContent = (
      <>
        {pre && (
          <Text whiteSpace="pre-wrap" fontSize="medium" fontFamily="inherit" mb={2}>
            {pre}
          </Text>
        )}
        <Box
          mb={2}
          fontSize="medium"
          fontFamily="inherit"
          overflowX="auto"
          dangerouslySetInnerHTML={{ __html: htmlBlock }}
        />
        {post && (
          <Text whiteSpace="pre-wrap" fontSize="medium" fontFamily="inherit" mb={2}>
            {post}
          </Text>
        )}
      </>
    );
  } else {
    // Show raw or pretty normally
    displayedContent = (
      <Text whiteSpace="pre-wrap" m={0} fontSize="medium" fontFamily="inherit">
        {showRaw ? raw : pretty}
      </Text>
    );
  }

  return (
    <HStack justify={styles.align}>
      {(type === 'ai' || type === 'human') && (
        <Avatar size="sm" name={type === 'ai' ? 'AI' : 'Human'} />
      )}

      <Box
        bg={!isLast ? styles.bg : 'blue.900'}
        color={styles.textColor}
        p={2}
        m={0}
        borderRadius="lg"
        borderTopLeftRadius={type === 'ai' ? '0' : 'lg'}
        borderTopRightRadius={type === 'human' ? '0' : 'lg'}
        overflow="auto"
        wordBreak="break-word"
      >
        {displayedContent}
        <HStack justify="flex-end" spacing={1} mt={1}>
          <Button size="x-small" m={0} p={1} onClick={() => setShowRaw((prev) => !prev)}>
            <Text m={0} p={0} fontSize="x-small">
              {showRaw ? 'Show Pretty' : 'Show Raw'}
            </Text>
          </Button>
          <Button size="x-small" m={0} p={1} onClick={onCopy}>
            <Text m={0} p={0} fontSize="x-small">
              Copy
            </Text>
          </Button>
        </HStack>
      </Box>
    </HStack>
  );
};

MessageBubble.propTypes = {
  type: PropTypes.oneOf(['system', 'human', 'ai', 'image_data']).isRequired,
  content: PropTypes.string.isRequired,
  isLast: PropTypes.bool,
};

MessageBubble.defaultProps = {
  isLast: false,
};

export default MessageBubble;