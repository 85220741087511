import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Text,
} from '@chakra-ui/react';

import { getTripRequestSupplierMessages, assignTripRequest } from '../actions/tripRequestActions';
import Loader from './Loader';

const AssignSupplier = (props) => {
  const { isOpen, onClose, id, postUpdateCallback } = props;

  const dispatch = useDispatch();
  const tripRequestSupplierMessages = useSelector((state) => state.tripRequestSupplierMessages);
  const { suppliers, loadingSuppliers } = tripRequestSupplierMessages;
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [submitButtonText, setSubmitButtonText] = useState('Asignar');
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const tripRequestAssign = useSelector((state) => state.tripRequestAssign);
  const { loading, error } = tripRequestAssign;
  useEffect(() => {
    if (!isOpen) return;
    dispatch(getTripRequestSupplierMessages(id, 1));
  }, [dispatch, id]);

  // Reset state when the modal is opened or closed
  useEffect(() => {
    if (isOpen) {
      setSubmitDisabled(true);
      setSubmitButtonText('Asignar');
      setSelectedSupplier(null); // Assuming null is the initial state for selectedSupplier
    }
  }, [isOpen]);

  // Reset state when the modal is opened or closed
  useEffect(() => {
    if (selectedSupplier === null || selectedSupplier === '') {
      setSubmitDisabled(true);
      setSubmitButtonText('Asignar');
    } else {
      setSubmitDisabled(false);
      setSubmitButtonText('Asignar');
    }
  }, [selectedSupplier]);
  useEffect(() => {
    if (!props?.supplierSelected || selectedSupplier || suppliers?.length === 0 || error || loadingSuppliers) return;
    const defaultValue = suppliers?.find((supplier) => supplier.name === props?.supplierSelected)?.id;
    setSelectedSupplier(defaultValue);
  }, [props?.supplierSelected, suppliers, loadingSuppliers]);

  const handleSelectorChange = (value) => {
    setSelectedSupplier(value);
  };

  const handleAssignSupplier = async () => {
    try {
      await dispatch(assignTripRequest(id, selectedSupplier));
      setSubmitDisabled(true);
      postUpdateCallback();

      onClose();
      setSelectedSupplier('');
      toast.success('Transportista asignado correctamente');
    } catch (error) {
      toast.error('Error al asignar transportista 😢');
      console.error('Error sending message:', error);
      console.error('Error sending message:', error.response.data);
    }
  };
  const handleDeleteSupplier = async () => {
    try {
      await dispatch(assignTripRequest(id, ''));
      setSubmitDisabled(true);
      postUpdateCallback();
      toast.success('Tu viaje ya no esta asignado a un transportista 😢');
      onClose();
      setSelectedSupplier('');
    } catch (error) {
      toast.error('Error al borrar transportista 😢');
      console.error('Error sending message:', error);
      console.error('Error sending message:', error.response.data);
    }
  };

  const customStyles = {
    backgroundColor: 'white',
    color: 'darkblue',
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setSelectedSupplier('');
        onClose();
      }}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent style={customStyles}>
        <ModalCloseButton />
        <ModalHeader>Asignar Transportista</ModalHeader>
        <ModalBody maxH="70vh" overflowY="auto">
          {loadingSuppliers ? (
            <Loader />
          ) : (
            <Box p={4}>
              {props?.supplierSelected && (
                <Box fontWeight={'normal'} color={'gray.900'} display={'flex'} gap={2}>
                  <Text mb={0}>Transportista Asignado:</Text>
                  <Text fontWeight={'bold'}>{props?.supplierSelected} </Text>
                </Box>
              )}
              {/* Create a select dropdown with each supplier as an option */}
              <Select
                placeholder={'Seleccionar Transportista'}
                value={selectedSupplier}
                color={'gray.800'}
                onChange={(e) => handleSelectorChange(e.target.value)}
                cy="select-supplier-dropdown"
              >
                {suppliers
                  ? suppliers.map((supplier) => (
                      <option key={supplier.id} value={supplier.id}>
                        {supplier.name}
                      </option>
                    ))
                  : null}
              </Select>
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Box display="flex" justifyContent="flex-end" gap={2} w="100%">
            {props?.supplierSelected && (
              <Button
                colorScheme="red"
                mr={3}
                onClick={handleDeleteSupplier}
                isLoading={loading}
                isDisabled={loading || loadingSuppliers}
                cy="clean-button"
              >
                Limpiar selección
              </Button>
            )}
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleAssignSupplier}
              isDisabled={loading ? true : isSubmitDisabled || loadingSuppliers}
              isLoading={loading}
              cy="assign-button"
            >
              {submitButtonText}
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AssignSupplier;
