import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { login } from '../actions/userActions';
import AppLayout from '../components/utils/layouts/AppLayout';
import LoginTemplate from '../templates/LoginTemplate';

function LoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = React.useState({});
  // Initialize the Redux dispatch function
  const dispatch = useDispatch();
  // Initialize navigation and location hooks
  const navigate = useNavigate();
  const location = useLocation();
  // Determine the redirect URL after login
  const redirect = location.search ? location.search.split('=')[1] : '/';

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;
  // Effect to navigate to the redirect URL if user is logged in
  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
      // navigate(-1)
    }
  }, [navigate, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (!email || !password) {
      setErrorMessage({
        ...errorMessage,
        ...(!email && { email: 'Email is required' }),
        ...(!password && { password: 'Password is required' }),
      });
      return;
    }
    dispatch(login(email, password));
  };

  return (
    <AppLayout>
      <LoginTemplate
        email={email}
        password={password}
        setEmail={setEmail}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        setPassword={setPassword}
        submitHandler={submitHandler}
        error={error}
        loading={loading}
      />
    </AppLayout>
  );
}

export default LoginScreen;
