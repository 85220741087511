import toast from 'react-hot-toast';

import { CREATE_TRIP_FAIL, CREATE_TRIP_REQUEST, CREATE_TRIP_SUCCESS } from '../constants/createTripConstants';
import httpClient from './utils/axiosInstance';
//create a new trip
/**
 * 
 * @param {Object} data  for create a new trip
 * @param {STRING} url - for trip availability and trip requests 
 * @returns global data
 */
export const createNewTrip =
  (data, url = 'trip-requests') =>
  async (dispatch) => {
    dispatch({ type: CREATE_TRIP_REQUEST });

    try {
      await httpClient.post(`/api/v1/${url}/create/`, data);

      dispatch({
        type: CREATE_TRIP_SUCCESS,
      });
      toast.success('Viaje creado correctamente');
      return Promise.resolve(); 
    } catch (error) {
      toast.error('Hubo un error creando el viaje 😢');
      dispatch({
        type: CREATE_TRIP_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
      return Promise.reject(error); 
    }
  };
