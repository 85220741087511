import React from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { Box } from '@chakra-ui/react';

import Footer from '../../Footer';
import Header from '../../Header';
import HeaderNavigator from '../navigators/Header';

const AppLayout = ({ children, overflowY = 'auto', showFooter = true }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  return (
    <Box
      position={'relative'}
      w={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      bg="#F5F5F5"
      m={0}
      p={0}
      overflowY="hidden"
      height={'100vh'}
    >
      <Box as="header" w="100%">
        {userInfo ? <Header /> : <HeaderNavigator />}
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        justifyContent={'center'}
        maxHeight="100%"
        w="100%"
        maxWidth={'1500px'}
        height={'100vh'}
        style={{
          paddingTop: userInfo ? '68px' : 0,
        }}
        as="main"
        m={0}
        px={5}
        overflowY={overflowY}
        sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
      >
        {children}
      </Box>
      {showFooter && (
        <Box as="footer" w="100%">
          <Container style={{ maxWidth: '1600px', margin: 'auto' }}>
            <Footer />
          </Container>
        </Box>
      )}
    </Box>
  );
};

export default AppLayout;
