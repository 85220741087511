import React from 'react';
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

const CustomContainer = styled(TableContainer)`
  max-height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
`;

const DataTable = ({ data, headers, title = "" }) => {
  console.log({ headersFromTable: headers })

  // Early return for unsupported data formats
  if (
    !Array.isArray(data) &&
    (typeof data !== 'object' || data === null)
  ) {
    return <div>Data format not supported</div>;
  }

  // Handle empty data array
  if (Array.isArray(data) && data.length === 0) {
    return null;
  }

  let processedHeaders = headers || [];
  let rows = [];

  if (Array.isArray(data)) {
    if (Array.isArray(data[0])) {
      // Data is a list of lists (rows)
      const maxColumns = Math.max(...data.map(row => row.length));
      rows = data.map(row => [
        ...row,
        ...Array(maxColumns - row.length).fill(''),
      ]);

    } else if (typeof data[0] === 'object' && data[0] !== null) {
      // Data is a list of objects
      const allKeys = Array.from(
        data.reduce((keys, obj) => {
          Object.keys(obj).forEach(key => keys.add(key));
          return keys;
        }, new Set())
      );
      // If headers not provided, use all keys
      if (!processedHeaders.length) {
        processedHeaders = allKeys;
      }

      rows = data.map(obj =>
        processedHeaders.map(key => (obj[key] !== undefined ? obj[key] : ''))
      );
    } else {
      // Data is a list of primitives
      rows = data.map(value => [value]);
      if (!processedHeaders.length) {
        processedHeaders = [""];
      }
    }
  } else {
    // Data is a single object
    const objKeys = Object.keys(data);
    if (!processedHeaders.length) {
      processedHeaders = objKeys;
    }
    rows = [processedHeaders.map(key => (data[key] !== undefined ? data[key] : ''))];
  }

  return (
    <CustomContainer>
      <Table variant="striped" colorScheme='blue' size="sm">
        <Thead>
          <Tr>
            <Th colSpan={processedHeaders.length} style={{ textTransform: "capitalize", fontSize: '1em' }}>
              {title.replaceAll("_", " ")}
            </Th>
          </Tr>
          {processedHeaders.length > 0 && (
            <Tr>
              {processedHeaders.map((header, index) => (
                <Th style={{ textTransform: "capitalize" }} key={index}>{header.replaceAll("_", " ")}</Th>
              ))}
            </Tr>
          )}
        </Thead>
        <Tbody>
          {rows.map((row, rowIndex) => (
            <Tr key={rowIndex}>
              {row.map((cell, cellIndex) => {
                let displayValue;
                if (cell === null || cell === '') {
                  displayValue = ' - ';
                } else if (typeof cell === 'object') {
                  // Convert nested objects/arrays to string for readability
                  displayValue = <DataTable title='' data={cell}/>;
                } else {
                  displayValue = cell
                }

                return <Td key={cellIndex}>{displayValue}</Td>;
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </CustomContainer>
  );
};

export default DataTable;