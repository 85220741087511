import toast from 'react-hot-toast';

import {
  BULK_APROVED_FAIL,
  BULK_APROVED_LOADING,
  BULK_APROVED_SUCCESS,
  BULK_DELETE_LOADING,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_FAIL,
} from '../constants/bulkConstants';
import httpClient from './utils/axiosInstance';
// functions that allows for the bulk approval and deletion of trip requests in the trip request view.
export const updateAprovedBulk = (ids) => async (dispatch) => {
  try {
    dispatch({ type: BULK_APROVED_LOADING });
    await httpClient.put(`/api/v1/trip-requests/approve/bulk/`, {
      is_approved: true,
      trip_requests: ids,
    });

    dispatch({
      type: BULK_APROVED_SUCCESS,
    });
  } catch (error) {
    toast.error('Failed to update all trip');
    dispatch({
      type: BULK_APROVED_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const updateDeletedBulk = (ids) => async (dispatch) => {
  try {
    dispatch({ type: BULK_DELETE_LOADING });

    await httpClient.put(`/api/v1/trip-requests/delete/bulk/`, {
      trip_requests: ids,
    });

    dispatch({
      type: BULK_DELETE_SUCCESS,
    });
  } catch (error) {
    toast.error('Failed to delete all trip request');
    dispatch({
      type: BULK_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
