import React, { useEffect, useState } from 'react';
import AppLayout from '../components/utils/layouts/AppLayout';
import { useParams } from 'react-router-dom';
import VerticalCollapser from '../components/pipelineDetails/View';
import axios from 'axios';
import { BASE_URL } from '../appSettings';
import PipelineEvent from '../components/pipelineDetails/Event';
import styled from '@emotion/styled';
import DataTable from '../components/pipelineDetails/Table';

const Container = styled.div`
  padding: 2em;
`;

// Function to fetch pipeline data
const fetchPipeline = async (pipelineId) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/pipeline/${pipelineId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching pipeline data:', error);
    return null;
  }
};

const PipelineDetails = () => {
  const { pipelineId } = useParams();
  const [pipelineData, setPipelineData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPipelineData = async () => {
      if (pipelineId) {
        const data = await fetchPipeline(pipelineId);
        setPipelineData(data);
        setLoading(false);
      }
    };

    loadPipelineData();
  }, [pipelineId]);


  if (loading) {
    return (
      <AppLayout showFooter={false}>
        <div>Loading...</div>
      </AppLayout>
    );
  }

  const createdEntities = pipelineData?.created_entities
  const tripRequests = createdEntities?.trip_requests

  const flattenObject = (obj, parentKey = '', result = {}) => {
    Object.keys(obj).forEach((key) => {
      const newKey = parentKey ? `${parentKey}_${key}` : key;

      if (
        typeof obj[key] === 'object' &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        flattenObject(obj[key], newKey, result);
      } else {
        result[newKey] = obj[key];
      }
    });
    return result;
  }

  const flattedTripTequests = tripRequests?.map((tripRequest) => {
    const flattened = flattenObject(tripRequest);
    return flattened;
  })


  const createdEntitiesTab = flattedTripTequests && flattedTripTequests.length > 0 ? {
    title: 'Created Entities',
    content: (
      <div style={{ display: "grid", justifyContent: "space-between", alignItems: "center", gap: "1rem", padding: "1rem" }}>
        <h4>Trip Requests</h4>
        <DataTable data={flattedTripTequests} />
      </div>
    ),
  } : null;

  const eventTabs = pipelineData?.events?.map((event, index) => {
    // TODO [START] It should not be handled here instead the data should be handled in the backend
    const previousEventsTables = pipelineData?.events?.slice(0, index).filter((e) => e.prompt?.tables?.length > 0);
    const onlyOneEventsHaveTables = previousEventsTables.length === 1;

    if (onlyOneEventsHaveTables) {

      const firstEventTables = previousEventsTables[0].prompt.tables;

      const previousEvent = pipelineData.events[index - 1];

      const previousHasTable = previousEvent.prompt?.tables?.length > 0;
      const currentEvenHasNotData = !event?.prompt?.data || event?.prompt?.data?.length == 0;

      if (currentEvenHasNotData && previousHasTable) {
        if (!event.prompt) {
          event.prompt = {}
        }
        event.prompt.inputData = [...firstEventTables]
        const columnFormat = event?.output?.response?.columns_format
        console.log({ columnFormat })

        event.prompt.output_headers = event?.output?.response?.columns_format

        console.log({ firstEventTables })

      }
    }
    // TODO [END] It should not be handled here instead the data should be handled in the backend

    return {
      title: `${index}# ${event.service.name} - id: ${event.service.id}`,
      content: <PipelineEvent event={event} />,
    };
  })




  if (!pipelineData) {
    return (
      <AppLayout showFooter={false}>
        <div>Error loading pipeline data. Please try again.</div>
      </AppLayout>
    );
  }

  return (
    <Container>
      <VerticalCollapser tabs={[...eventTabs, createdEntitiesTab].filter(_ => _)} />
    </Container>
  );
};

export default PipelineDetails;