// ChatComponent.jsx

import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import MessageBubble from './MessageBubble';

const ChatComponent = ({ messages }) => {
  const scrollContainerRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (isExpanded) {
      scrollToBottom();
    }
  }, [messages, isExpanded]);

  const handleAccordionToggle = (expandedIndex) => {
    const currentlyExpanded = expandedIndex === 0;
    setIsExpanded(currentlyExpanded);
    if (currentlyExpanded) {
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  };

  return (
    <Box bg={isExpanded ? "blue.200" : "blue.100"} borderBottom={"1px solid gray"} p={2}>
      <Accordion allowToggle onChange={handleAccordionToggle}>
        <AccordionItem p={0} border="none">
          <AccordionButton
            _hover={{ bg: 'blue.300' }}
            bg={isExpanded ? 'blue.300' : 'blue.200'}
            borderRadius="md"
            boxShadow="md"
            p={2}
          >
            <HStack p={0} flex="1" alignContent="center" justify="space-between">
              <Text p={0} m={0} fontSize="sm" fontWeight="bold">
                Prompt Messages
              </Text>
              <AccordionIcon />
            </HStack>
          </AccordionButton>
          <AccordionPanel pb={4} borderRadius="md" boxShadow="md" mt={2}>
            <Box
              maxH="500px"
              overflowY="auto"
              pr={2}
              ref={scrollContainerRef}
            >
              <VStack spacing={4} align="stretch">
                {messages.map((msg, index) => {
                  const [type, content] = Object.entries(msg)[0];
                  const isLast = index === messages.length - 1;
                  return (
                    <MessageBubble
                      key={index}
                      type={type}
                      content={content}
                      isLast={isLast}
                    />
                  );
                })}
              </VStack>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

ChatComponent.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({ system: PropTypes.string.isRequired }),
      PropTypes.shape({ human: PropTypes.string.isRequired }),
      PropTypes.shape({ ai: PropTypes.string.isRequired }),
      PropTypes.shape({ image_data: PropTypes.string.isRequired }),
    ])
  ).isRequired,
};

export default ChatComponent;