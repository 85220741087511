/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAvailabilityDetail, listAvailabilities, unreadAvailability } from '../../actions/availabilityActions';
import { listClientsDropdown, listTransportationClientsDropdown } from '../../actions/clientActions';
import { listDropdownMunicipality, listMunicipalitiesDropdown } from '../../actions/municipalityActions';
import { listUnitTypesDropdown } from '../../actions/unitTypeActions';
import AppLayout from '../../components/utils/layouts/AppLayout';
import AvialabilityTemplate from '../../templates/AvailabilityTemplate';
import moment from 'moment';

const AvailabilityScreen = () => {
  const dispatch = useDispatch();
  const availabilitiesView = useSelector((state) => state.availabilitiesList);
  const { availabilities, error, filters, pagination, loading, refreshing } = availabilitiesView;

  const userDetailsView = useSelector((state) => state.userDetails);
  const { userDetails } = userDetailsView;
  const [currentId, setCurrentId] = useState(null);

  const [withMaxScreen, setWithMaxScreen] = useState(1375);
  const [readStatus, setReadStatus] = React.useState({});
  const [disabledButtons, setDisabledButtons] = React.useState({});
  const [selectedOption, setSelectedOption] = React.useState();
  const [selectedOptions, setSelectedOptions] = React.useState({});
  const [selectedFilters, setSelectedFilters] = React.useState({});
  const [tablePage, setTablePage] = useState(1);
  const [elementShow, setElementShow] = useState(40);
  const [sortConfig, setSortConfig] = useState({
    key: 'offered_at',
    direction: 'desc',
  });
  const handleColumnSort = (column) => {
    const newDirection = sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key: column.id, direction: newDirection });
  };
  // Toggle read status of availability
  const handleReadStatusToggle = (id) => {
    const currentStatus = readStatus[id];

    setDisabledButtons(() => ({
      [id]: true,
    }));
    setReadStatus((prevReadStatus) => ({
      ...prevReadStatus,
      [id]: !prevReadStatus[id],
    }));
    // Toggle read/unread status using Redux action
    if (currentStatus) {
      dispatch(unreadAvailability(id)).then(() => {
        setDisabledButtons({});
      });
    } else {
      dispatch(getAvailabilityDetail(id)).then(() => {
        setDisabledButtons({});
      });
    }
  };
  // Refresh availabilities based on filters, sorting, and pagination
  const refreshAvailabilities = () => {
    dispatch(
      listAvailabilities({
        ordering: sortConfig.direction,
        order_by: sortConfig.key,
        page: tablePage,
        page_size: elementShow,
        // Include selected filters
        refresh: true,
        ...(!userDetails?.is_staff && { offered_at_after: moment(new Date()).format('YYYY-MM-DD') }),
        ...selectedFilters,
      })
    );
  };
  // Fetch initial data based on current filters, sorting, and pagination
  useEffect(() => {
    dispatch(
      listAvailabilities({
        refresh: false,
        page: tablePage,
        page_size: elementShow,
        ordering: sortConfig.direction,
        order_by: sortConfig.key,
        ...(!userDetails?.is_staff && { offered_at_after: moment(new Date()).format('YYYY-MM-DD') }),
        ...selectedFilters,
      })
    );
  }, [tablePage, elementShow, selectedFilters, dispatch, sortConfig]);
  // Fetch dropdown data on component mount or when error occurs
  useEffect(() => {
    if (error) return;

    dispatch(listMunicipalitiesDropdown());
  }, [dispatch]);

  useEffect(() => {
    if (error) return;

    dispatch(listDropdownMunicipality());
  }, [dispatch]);

  useEffect(() => {
    if (error) return;

    dispatch(listClientsDropdown());
  }, [dispatch]);

  useEffect(() => {
    if (error) return;
    dispatch(listUnitTypesDropdown());
  }, [dispatch]);

  useEffect(() => {
    if (error) return;
    dispatch(listTransportationClientsDropdown());
  }, [dispatch]);

  useEffect(() => {
    if (error) return;
    const buildSelectedFilters = Object?.entries(selectedOptions)
      .filter(([_, value]) => !!value)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
    setSelectedFilters(buildSelectedFilters);
    setTablePage(1);
  }, [selectedOptions, selectedOption]);

  useEffect(() => {
    if (availabilities) {
      const initialReadStatus = {};
      availabilities.forEach((request) => {
        initialReadStatus[request.id] = request.seen_by_user;
      });
      setReadStatus(initialReadStatus);
    }
  }, [availabilities]);
  return (
    <AppLayout>
      <AvialabilityTemplate
        availabilities={availabilities}
        handleReadStatusToggle={handleReadStatusToggle}
        readStatus={readStatus}
        disabledButtons={disabledButtons}
        setSelectedOption={setSelectedOption}
        setSelectedOptions={setSelectedOptions}
        filters={filters}
        withMaxScreen={withMaxScreen}
        setWithMaxScreen={setWithMaxScreen}
        pagination={pagination}
        setTablePage={setTablePage}
        setElementShow={setElementShow}
        elementShow={elementShow}
        error={error}
        loading={loading}
        currentId={currentId}
        setCurrentId={setCurrentId}
        refreshAvailabilities={refreshAvailabilities}
        tablePage={tablePage}
        selectedFilters={selectedFilters}
        handleColumnSort={handleColumnSort}
        sortConfig={sortConfig}
        is_staff={userDetails?.is_staff}
        selectedOptions={selectedOptions}
      />
    </AppLayout>
  );
};

export default AvailabilityScreen;
