import React from 'react';

import AppLayout from '../components/utils/layouts/AppLayout';
import TripTableTemplate from '../templates/TripTableTemplate';

function TripRequestListScreen() {
  //This Screen is used to display the list of trip requests
  return (
    <AppLayout>
      <TripTableTemplate />
    </AppLayout>
  );
}

export default TripRequestListScreen;
